<template>
      <v-container>
        <h2 >Featured Products</h2>
        <p>Bringing you only the best features for better workload management and smarter business decisions.</p>
        <Product v-bind:info="arrays.info"></Product>
      </v-container>
</template>

<script>
import Product from "@/components/Product/productspartial";
export default {
  components: {
    Product,
  },
  props: {
    myInfo : Object
  },
  watch: {
    myInfo : function(val)
    {
      this.arrays.info = val
      this.checkAdmin()
    }
  },
  created() {
    if(this.myInfo)
    {
      this.arrays.info = this.myInfo
      this.checkAdmin()
    }
  },
  data: () => ({
    active_subscriptions: [],
    headers: [
      {
        text: "Product name",
        align: "start",
        value: "products.name",
      },
      { text: "Start of subscription", value: "start_of_subscription" },
      { text: "End of subscription", value: "end_of_subscription" },
    ],
    info: {},
    arrays : {
      info : {},
      unpaid : {},
    },
    status : {
      unpaid : false,
      admin : false
    },
    pagination : {
      unpaid : null
    }
  }),
  methods: {
    goto()
    {
      this.$router.push({name : 'UnpaidSubscription'})
    },
    checkAdmin()
    {
      if(this.arrays.info.default_user_company.role == 'SA')
      {
        this.status.admin = true
      }else{
        this.status.admin = false
      }
    },
  },
};
</script>


<style scoped>
#router {
  text-decoration: none;
}
</style>